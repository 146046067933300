<template>
	<div class="pui-form" id="imo33">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
			<v-tabs v-model="tabmodel" class="ml-3 mb-3">
				<v-tab :key="0" id="imo3items" :href="'#imofalform3itemstab'">{{ $t('imofalform3.imofalform3itemstab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform3itemstab'">
					<v-row dense style="margin-top: -15px" class="pui-form-layout">
						<v-col cols="12">
							<pui-field-set :title="$t('imofalform3items.fieldset.storeDetails')">
								<v-row dense>
									<!-- is it a bunker -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="`indbunker-imofalform3items`"
											:label="$t('imofalform3items.isABunker')"
											:attach="`indbunker-imofalform3items`"
											itemText="text"
											itemValue="value"
											required
											toplabel
											:disabled="formDisabled"
											v-model="model"
											:items="codes"
											reactive
											:itemsToSelect="itIsABunkerToSelect"
											:modelFormMapping="{ value: 'indbunker' }"
										></pui-select>
									</v-col>
									<!-- ITEMNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											v-if="
												this.model.indbunker === '0' ||
												this.model.indbunker === null ||
												(this.model.indbunker === '0' && !this.isCreatingElement)
											"
											:id="`itemname-imofalform3items`"
											v-model="model.itemname"
											:label="$t('imofalform3items.itemname')"
											:disabled="formDisabled"
											toplabel
											maxlength="100"
											required
										></pui-text-field>
										<pui-select
											v-if="this.model.indbunker === '1' || (this.model.indbunker === '1' && !this.isCreatingElement)"
											id="`imofalform3items.bunkertypecode`"
											attach="imofalform3items.bunkertypecode"
											:label="$t('imofalform3bunkers.bunkertypecode')"
											toplabel
											reactive
											v-model="model"
											:disabled="formDisabled"
											modelName="mbunkertypes"
											:modelFormMapping="{ bunkertypecode: 'bunkertypecode' }"
											:itemsToSelect="itemToSelectBunkertypecode"
											:itemValue="['bunkertypecode']"
											:itemText="(item) => `${item.bunkertypecode} - ${item.typedescription}`"
										></pui-select>
									</v-col>

									<!-- QUANTITY -->
									<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-number-field
											:id="`quantity-imofalform3items`"
											v-model="model.quantity"
											:label="$t('imofalform3items.quantity')"
											:disabled="formDisabled"
											required
											toplabel
											m
										></pui-number-field>
									</v-col>
									<!-- MEASUREMENTUNITCODE -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`measurementunitcode-imofalform3items`"
											:attach="`measurementunitcode-imofalform3items`"
											:label="$t('imofalform3items.measurementunitcode')"
											required
											toplabel
											clearable
											modelName="mmeasurementunits"
											v-model="model"
											reactive
											:disabled="formDisabled"
											:itemsToSelect="measurementUnitCodeItemsToSelect"
											maxlength="5"
											:modelFormMapping="{ measurementunitcode: 'measurementunitcode' }"
											:itemValue="['measurementunitcode']"
											:itemText="(item) => `${item.measurementunitsymbol} - ${item.typedescription}`"
										></pui-select>
									</v-col>
								</v-row>

								<v-row dense style="margin-top: -15px">
									<!-- LOCATION -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<pui-text-field
											:id="`location-imofalform3items`"
											v-model="model.location"
											:label="$t('imofalform3items.location')"
											:disabled="formDisabled"
											toplabel
										></pui-text-field>
									</v-col>
									<!-- OFFICIALUSE -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<pui-text-field
											:id="`officialuse-imofalform3items`"
											v-model="model.officialuse"
											:label="$t('imofalform3items.officialuse')"
											:disabled="formDisabled"
											toplabel
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
				<!-- Page Footer -->
				<pui-form-footer>
					<pui-form-footer-btns
						:formDisabled="!havePermissions(['WRITE_IMOFALFORM3ITEMS'])"
						:saveDisabled="saving"
						:saveAndNew="saveAndNew"
						:saveAndUpdate="saveAndUpdate"
						:save="save"
						:back="back"
					></pui-form-footer-btns>
				</pui-form-footer>
			</v-tabs-items>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import imofalform3Actions from '../imofalform3menu/ImoFalForm3Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform3items-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform3items',
			actions: imofalform3Actions.actions,
			parentModelName: 'imofalform3menu',
			tabmodel: 'imofalform3items',
			codes: [
				{
					text: this.$t('imofalform3items.yes'),
					value: '1'
				},
				{
					text: this.$t('imofalform3items.no'),
					value: '0'
				}
			]
		};
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			// Do something after get data from server
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
			}
			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).imofalformid;
			}
		}
	},
	watch: {
		/*
		'model.indbunker'() {
			if (!this.isCreatingElement) {
				this.model.indbunker = '0';
			}
		}
		*/
	},
	computed: {
		itemToSelectBunkertypecode() {
			return [{ bunkertypecode: this.model.bunkertypecode }];
		},
		measurementUnitCodeItemsToSelect() {
			return [{ measurementunitcode: this.model.measurementunitcode }];
		},
		itIsABunkerToSelect() {
			return [{ value: this.model.indbunker }];
		}
	},
	created() {}
};
</script>
