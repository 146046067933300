import printReportAction from '@/components/nsw-components/PrintReportsAction';
import DocumentNewVersionAction from '@/components/nsw-components/DocumentNewVersionAction';

export default {
	actions: [
		printReportAction.printReport('STORE_DECLARATION', 'imofalformid', 'reports.label.falForms.storeDeclaration'),
		DocumentNewVersionAction.newVersion({
			imofalformtypeid: 3
		})
	]
};
